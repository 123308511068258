<template>
    <picture>
        <source v-if="selfHosted && !isSvg" :srcset="sourceSet" type="image/webp" />
        <img v-bind="imageProps" :class="imageClass" loading="lazy" />
    </picture>
</template>
<script setup lang="ts">
const props = defineProps({
    src: { type: String, required: true },
    cssClass: { type: String, default: null },
    alt: { type: String, default: null },
    title: { type: String, default: null },
    height: { type: String, default: null },
    width: { type: String, default: null },
    id: { type: String, default: null },
    multiResolution: {
        // Enabling multiResolution requires that selfHosted is true, this is so
        // we can use the same component for external or selfHosted images.
        // if multiResolution == true && selfHosted == true, we can assume the
        // higher resolution versions of the image are available.
        type: Boolean,
        default: false,
    },
    selfHosted: {
        // This component was originally used to upgrade jpg company images
        // to webp so the default value is true, however, this component
        // is now used as well in places where the image may be selfHosted
        // or it may come from a third-party site. If the image is not
        // selfHosted, we must use the exact image URL specified in the data.
        // If the image is selfHosted, we can assume the webp version is
        // available.
        type: Boolean,
        default: true,
    },
});

const imageProps = computed(() => {
    const { id, alt, title, width, height, src } = props;
    return { id, alt, title, width, height, src };
});

const sourceSet = computed(() => {
    if (props.selfHosted) {
        if (isSvg.value) {
            return props.src;
        } else if (props.multiResolution) {
            return `${props.src}.webp, ${props.src}.1.5x.webp 1.5x, ${props.src}.2.0x.webp 2x`;
        } else {
            return `${props.src}.webp`;
        }
    } else {
        return props.src;
    }
});

const svgRegEx = /\.svg$/;
const isSvg = computed(() => svgRegEx.test(props.src));

const imageClass = computed(() => cn("object-scale-down object-left", props.cssClass));
</script>
